import { Common } from 'lib/shopify/types'

import {
  API_TOKEN,
  API_URL,
  I18N_API_TOKEN,
  I18N_API_URL,
} from '@shopify/const'
import { getError } from './handle-fetch-response'

export default async function fetchGraphqlApi<T>({
  cache = 'force-cache',
  headers,
  query,
  locale,
  variables,
  fetchOptions,
}: {
  cache?: RequestCache
  headers?: HeadersInit
  query: string
  locale?: string
  tags?: string[]
  variables?: any
  fetchOptions?: any
}): Promise<{ status: number; res: T; errors?: any } | never> {
  const LOCALE = locale ?? 'us'
  const TOKEN = I18N_API_TOKEN[LOCALE] ?? API_TOKEN
  const URL = I18N_API_URL[LOCALE] ?? API_URL

  try {
    const result = await fetch(URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Shopify-Storefront-Access-Token': TOKEN,
        ...headers,
      },
      body: JSON.stringify({
        ...(query && { query }),
        ...(variables && { variables }),
      }),
      ...fetchOptions,
    })

    const { data, errors, status } = await result.json()

    if (errors) {
      return { res: data, errors, status }
    }

    return { res: data, status }
  } catch (err) {
    throw getError(
      [
        {
          message: `${err} \n Most likely related to an unexpected output. e.g the store might be protected with password or not available.`,
        },
      ],
      500
    )
  }
}
